<template>
    <div v-if="!loading" class="card">
        <slot></slot>
    </div>
    <div v-else class="card">
        <div class="card-header text-muted"><loading></loading> Laden...</div>
        <div class="card-body"></div>
    </div>
</template>

<script>
    export default {
        props: ['loading']
    }
</script>
