<template>
    <tiny-editor :init="config" v-model="mutableValue"></tiny-editor>
</template>

<script>
    import TinyEditor from '@tinymce/tinymce-vue';

    export default {
        components: {TinyEditor},
        props: {
            'init': Object,
            value: String
        },
        created() {
            this.mutableValue = this.value;
            if(this.init) {
                this.config = this.init;
            }
        },
        data() {
            return {
                mutableValue: undefined,
                config: {
                    plugins: 'code link paste image',
                    toolbar: 'undo redo | styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image forecolor backcolor | code',
                    height: 310,
                    images_upload_handler: (blob, success, failure) => {
                        let formData = new FormData();
                        formData.append('file', blob.blob(), blob.filename());
                        this.$http.post('/media/upload', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then((response) => {
                            success(response.data.location);
                        }).catch(failure);
                    },
                    image_list: (done) => {
                        this.$http.get('/media/list').then((response) => {
                            done(response.data);
                        });
                    },
                    image_advtab: true,
                    extended_valid_elements: 'contact-component[*],contact-telephone-component[*]',
                    custom_elements: 'contact-component,contact-telephone-component',
                    relative_urls: false
                }
            };
        },
        watch: {
            mutableValue() {
                this.$emit('input', this.mutableValue);
            }
        }
    }
</script>
