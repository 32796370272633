import Router from 'vue-router'
import { token } from './../services/Auth.js'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');

// Views
const Dashboard = () => import('@/views/dashboard/Index');
const PageNotFound = () => import('@/views/PageNotFound');
const Login = () => import('@/views/auth/Login');

const TranslationsIndex = () => import('@/views/translations/Index');

const UsersIndex = () => import('@/views/users/Index');
const UsersShow = () => import('@/views/users/Show');
const UsersCreate = () => import('@/views/users/Create');

const ProductsIndex = () => import('@/views/products/Index');
const ProductsShow = () => import('@/views/products/Show');
const ProductsCreate = () => import('@/views/products/Create');

const PaymentMethodsIndex = () => import('@/views/payment_methods/Index');
const PaymentMethodsShow = () => import('@/views/payment_methods/Show');
const PaymentMethodsCreate = () => import('@/views/payment_methods/Create');

const OrdersIndex = () => import('@/views/orders/Index');
const OrdersShow = () => import('@/views/orders/Show');

const TransportersIndex = () => import('@/views/transporters/Index');
const TransportersShow = () => import('@/views/transporters/Show');
const TransportersCreate = () => import('@/views/transporters/Create');

const TransporterZonesIndex = () => import('@/views/transporter_zones/Index');
const TransporterZonesShow = () => import('@/views/transporter_zones/Show');
const TransporterZonesCreate = () => import('@/views/transporter_zones/Create');

const TransportIndex = () => import('@/views/transport/Index');

const PagesIndex = () => import('@/views/pages/Index');
const PagesShow = () => import('@/views/pages/Show');
const PagesCreate = () => import('@/views/pages/Create');

const PickupLocationsIndex = () => import('@/views/pickup_locations/Index');
const PickupLocationsShow = () => import('@/views/pickup_locations/Show');
const PickupLocationsCreate = () => import('@/views/pickup_locations/Create');

const ZonesIndex = () => import('@/views/zones/Index');
const ZonesShow = () => import('@/views/zones/Show');
const ZonesCreate = () => import('@/views/zones/Create');

const AdministrationIndex = () => import('@/views/administration/Index');

const SettingsIndex = () => import('@/views/settings/Index');

const ExtraGroupMessage = () => import('@/views/extra/GroupMessage');
const ExtraLog = () => import('@/views/extra/Log');

const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: DefaultContainer,
            beforeEnter: (to, from, next) => {
                if(!token()) {
                    return next({name: 'login'});
                }
                return next();
            },
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: Dashboard
                },
                {
                    path: '/translations',
                    name: 'translations.index',
                    component: TranslationsIndex
                },
                {
                    path: '/users',
                    name: 'users.index',
                    component: UsersIndex,
                    meta: {
                        label: window.VueI18n.t('crud.users._plural')
                    },
                },
                {
                    path: '/users/create',
                    name: 'users.create',
                    component: UsersCreate,
                    meta: {
                        label: window.VueI18n.t('crud.users._create')
                    }
                },
                {
                    path: '/users/:id',
                    name: 'users.show',
                    component: UsersShow,
                    meta: {
                        label: window.VueI18n.t('crud.users._show')
                    }
                },
                {
                    path: '/products',
                    name: 'products.index',
                    component: ProductsIndex,
                    meta: {
                        label: window.VueI18n.t('crud.products._plural')
                    },
                },
                {
                    path: '/products/create',
                    name: 'products.create',
                    component: ProductsCreate,
                    meta: {
                        label: window.VueI18n.t('crud.products._create')
                    }
                },
                {
                    path: '/products/:id',
                    name: 'products.show',
                    component: ProductsShow,
                    meta: {
                        label: window.VueI18n.t('crud.products._show')
                    }
                },
                {
                    path: '/payment-methods',
                    name: 'payment_methods.index',
                    component: PaymentMethodsIndex,
                    meta: {
                        label: window.VueI18n.t('crud.payment_methods._plural')
                    },
                },
                {
                    path: '/payment-methods/create',
                    name: 'payment_methods.create',
                    component: PaymentMethodsCreate,
                    meta: {
                        label: window.VueI18n.t('crud.payment_methods._create')
                    }
                },
                {
                    path: '/payment-methods/:id',
                    name: 'payment_methods.show',
                    component: PaymentMethodsShow,
                    meta: {
                        label: window.VueI18n.t('crud.payment_methods._show')
                    }
                },
                {
                    path: '/orders/:id',
                    name: 'orders.show',
                    component: OrdersShow,
                    meta: {
                        label: window.VueI18n.t('crud.orders._show')
                    }
                },
                {
                    path: '/orders',
                    name: 'orders.index',
                    component: OrdersIndex,
                    meta: {
                        label: window.VueI18n.t('crud.orders._plural')
                    },
                },
                {
                    path: '/transporters',
                    name: 'transporters.index',
                    component: TransportersIndex,
                    meta: {
                        label: window.VueI18n.t('crud.transporters._plural')
                    },
                },
                {
                    path: '/transporters/create',
                    name: 'transporters.create',
                    component: TransportersCreate,
                    meta: {
                        label: window.VueI18n.t('crud.transporters._create')
                    }
                },
                {
                    path: '/transporters/:id',
                    name: 'transporters.show',
                    component: TransportersShow,
                    meta: {
                        label: window.VueI18n.t('crud.transporters._show')
                    }
                },
                {
                    path: '/transporter-zones',
                    name: 'transporter_zones.index',
                    component: TransporterZonesIndex,
                    meta: {
                        label: window.VueI18n.t('crud.transporter_zones._plural')
                    },
                },
                {
                    path: '/transporter-zones/create',
                    name: 'transporter_zones.create',
                    component: TransporterZonesCreate,
                    meta: {
                        label: window.VueI18n.t('crud.transporter_zones._create')
                    }
                },
                {
                    path: '/transporter-zones/:id',
                    name: 'transporter_zones.show',
                    component: TransporterZonesShow,
                    meta: {
                        label: window.VueI18n.t('crud.transporter_zones._show')
                    }
                },
                {
                    path: '/transport',
                    name: 'transport.index',
                    component: TransportIndex,
                    meta: {
                        label: 'Transport'
                    },
                },
                {
                    path: '/pages',
                    name: 'pages.index',
                    component: PagesIndex,
                    meta: {
                        label: window.VueI18n.t('crud.pages._plural')
                    },
                },
                {
                    path: '/pages/create',
                    name: 'pages.create',
                    component: PagesCreate,
                    meta: {
                        label: window.VueI18n.t('crud.pages._create')
                    }
                },
                {
                    path: '/pages/:id',
                    name: 'pages.show',
                    component: PagesShow,
                    meta: {
                        label: window.VueI18n.t('crud.pages._show')
                    }
                },
                {
                    path: '/pickup-locations',
                    name: 'pickup_locations.index',
                    component: PickupLocationsIndex,
                    meta: {
                        label: window.VueI18n.t('crud.pickup_locations._plural')
                    },
                },
                {
                    path: '/pickup-locations/create',
                    name: 'pickup_locations.create',
                    component: PickupLocationsCreate,
                    meta: {
                        label: window.VueI18n.t('crud.pickup_locations._create')
                    }
                },
                {
                    path: '/pickup-locations/:id',
                    name: 'pickup_locations.show',
                    component: PickupLocationsShow,
                    meta: {
                        label: window.VueI18n.t('crud.pickup_locations._show')
                    }
                },
                {
                    path: '/zones',
                    name: 'zones.index',
                    component: ZonesIndex,
                    meta: {
                        label: window.VueI18n.t('crud.zones._plural')
                    },
                },
                {
                    path: '/zones/create',
                    name: 'zones.create',
                    component: ZonesCreate,
                    meta: {
                        label: window.VueI18n.t('crud.zones._create')
                    }
                },
                {
                    path: '/zones/:id',
                    name: 'zones.show',
                    component: ZonesShow,
                    meta: {
                        label: window.VueI18n.t('crud.zones._show')
                    }
                },
                {
                    path: '/administration',
                    name: 'administration.index',
                    component: AdministrationIndex,
                    meta: {
                        label: window.VueI18n.t('crud.administration.title')
                    }
                },
                {
                    path: '/settings',
                    name: 'settings.index',
                    component: SettingsIndex,
                    meta: {
                        label: window.VueI18n.t('crud.settings._plural')
                    }
                },
                {
                    path: '/extra/log',
                    name: 'extra.log',
                    component: ExtraLog
                },
                {
                    path: '/extra/group-message',
                    name: 'extra.group_message',
                    component: ExtraGroupMessage,
                    meta: {
                        label: window.VueI18n.t('crud.group_message.title')
                    }
                }
            ]
        },
        {
            path: '/',
            name: '',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: Login
                }
            ]
        },
        {
            path: '*',
            name: '404',
            component: PageNotFound
        }
    ]
});

// Save default page title.
let rootTitle = document.title;

// Set page title.
router.beforeEach((to, from, next) => {
    if(to.meta && to.meta.label) {
        document.title = to.meta.label + ' - ' + rootTitle;
    } else {
        document.title = rootTitle;
    }
    return next();
});

export default router;
