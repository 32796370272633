// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import VueI18n from 'vue-i18n';
import BootstrapVue from 'bootstrap-vue';
import App from './App';

import Router from 'vue-router';
import Toastr from 'vue-toastr';
import Api from './services/Api';
import Auth from './services/Auth';
import Moment from 'moment';
import $ from 'jquery/dist/jquery';

// Custom components.
import Loading from './views/_shared/Loading.vue';
import LoadingButton from './views/_shared/LoadingButton.vue';
import LoadingCard from './views/_shared/LoadingCard.vue';
import Vuetable from 'vuetable-2';
import Cookie from 'js-cookie';
import Editor from './views/_shared/Editor.vue';

Vue.prototype.$config = process.env;

window.$ = window.jQuery = $;
window.moment = Moment;
window.locale = Vue.prototype.$config.VUE_APP_LOCALE;
window.debounce = require('lodash.debounce');

require('tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4-build.scss');
require('./shared/prototype');
require('font-awesome/css/font-awesome.css');
require('tempusdominus-bootstrap-4');
require('tinymce/tinymce');
require('vue-toastr/src/vue-toastr.scss');

window.tinymce.baseURL = 'tinymce-assets';

// Add zero expiration to error toastr's.
let defaultErr = Toastr.methods.e;
Toastr.methods.e = function(msg, title) {
    defaultErr.call(this, {
        msg: msg,
        title: title,
        timeout: 0
    });
};

Vue.use(Toastr, {
    defaultPosition: 'toast-top-center',
    defaultTimeout: 3000,
    defaultProgressBarValue: 0
});
Vue.prototype.$toastr.defaultPreventDuplicates = true;

Vue.prototype.$http = new Api({
    baseUrl: Vue.prototype.$config.VUE_APP_API_URL
});

Vue.prototype.$auth = new Auth();

Vue.use(Router);
Vue.use(BootstrapVue);
Vue.use(VueI18n);

Vue.filter('toCurrency', function (value) {
    value = parseFloat(value);
    let formatter = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
});

Vue.filter('date', function(value, time) {
    let format = window.locale === 'de' ? 'DD.MM.YYYY' : 'DD-MM-YYYY';
    if(time) {
        format += ' HH:mm:ss';
    }
    return Vue.prototype.$moment(value).format(format);
});

Vue.component('vuetable', Vuetable);
Vue.component('loading', Loading);
Vue.component('loading-button', LoadingButton);
Vue.component('loading-card', LoadingCard);
Vue.component('editor', Editor);

Vue.mixin({
    methods: {
        /**
         * Only allows number input (digits and dot).
         * @param {object} e
         * @return {boolean}
         */
        onlyNumber: function(e) {
            e = e ? e : window.event;
            let charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
                e.preventDefault();
                if(charCode === 44) {
                    // Comma entered. Fill in a dot (.)
                    e.target.value = e.target.value + '.';
                }
            } else {
                return true;
            }
        }
    }
});

Vue.prototype.$moment = Moment;
Moment.locale(window.locale);

// Set cookie helper function.
Vue.prototype.$cookie = Cookie;
Cookie.defaults = {
    secure: Vue.prototype.$config.VUE_APP_COOKIE_SECURE === 'true',
    domain: Vue.prototype.$config.VUE_APP_COOKIE_DOMAIN || undefined
};

/**
 * Add x working days to a date.
 * Example: if the weekend are not working dates, adding 2 days to friday will result in a tuesday.
 * @param {Number} days
 */
Vue.prototype.$moment.prototype.addWorkingDays = function(days) {
    let workingDays = {
        0: false,   // sunday
        1: false,   // monday
        2: true,    // tuesday
        3: true,    // wednesday
        4: true,    // thursday
        5: true,    // friday
        6: true,    // saturday
    };
    while(days > 0) {
        this.add(1, 'day');
        if(workingDays[this.day()]) {
            days--;
        }
    }
    return this;
};

window.VueI18n = new VueI18n({
    locale: window.locale || 'nl',
    fallbackLocale: window.locale === 'de' ? 'nl' : 'de',
    messages: {
        nl: require('./lang/nl.json'),
        de: require('./lang/de.json')
    }
});

window.Vue = new Vue({
    i18n: window.VueI18n,
    el: '#app',
    router: require('./router').default,
    template: '<App/>',
    components: {
        App
    }
});

